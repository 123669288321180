import { UnknownElementError } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
/**
 * Container for a menu of a restaurant. (a restaurant can have multiple menus)
 */
export class Menu {
    constructor(params) {
        this.productCategories = params.productCategories;
        this.productTypes = params.productTypes;
        this.modifierTypes = params.modifierTypes;
        this.customParameterTypes = params.customParameterTypes;
        this.schedules = params.schedules;
        this.arrangments = params.arrangments;
        this.preorderSettings = params.preorderSettings;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        makeImmutable(this);
    }
    /**
     * @returns are the provided product types included in the menu?
     */
    includes(productTypeIds) {
        return productTypeIds.isSubsetOf(this.productTypes.ids);
    }
    getProductTypeMenuSection(productTypeId) {
        for (const arragment of this.arrangments.objects) {
            const sectionId = arragment.getProductTypeMenuSection(productTypeId);
            if (sectionId !== null) {
                return sectionId;
            }
        }
        throw new UnknownElementError();
    }
}
