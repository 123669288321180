import { RSet } from "../../collections";
import { makeImmutable } from "../../core";
import { Tristate } from "../../core/Tristate";
/*
 * Availability strategy based on Ordering Hours
 */
export class OrderingHoursAvailability {
    constructor(params) {
        this.scope = params.scope;
        this.orderingHours = params.orderingHours;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        makeImmutable(this);
    }
    get isAvailable() {
        return this.allowOrderingInClosedRestaurant
            ? Tristate.True
            : this.orderingHours.canOrderAt(this.scope.now)
                ? Tristate.True
                : Tristate.False;
    }
    get unavailabilityReasons() {
        return this.isAvailable.isDefinitelyTrue
            ? RSet.empty()
            : RSet.singleton("OrderingHours");
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitOrderingHours(this);
    }
}
