import I18n from "i18n-js";
import { makeAutoObservable, runInAction } from "mobx";
import { PProductError, Sentry, } from "..";
export class PCheckout {
    constructor(params) {
        this.purchaseOrder = params.purchaseOrder;
        this.restaurant = params.restaurant;
        this.locale = params.locale;
        this.checkoutService = params.checkoutService;
        this.orderDetails = params.orderDetails;
        this.fulfillmentTime = params.fulfillmentTime;
        this.geolocation = params.geolocation;
        this.fulfillmentFormController = params.fulfillmentFormController;
        this.paymentFormController = params.paymentFormController;
        this.contactFormController = params.contactFormController;
        this.consentsFormController = params.consentsFormController;
        this.invoiceFormController = params.invoiceFormController;
        this.fulfillmentFormReactions = params.fulfillmentFormReactions;
        this.checkoutTracking = params.checkoutTracking;
        this.placeOrderCallback = params.placeOrderCallback;
        this.tip = params.tip;
        this.fieldTestsStorageManager = params.fieldTestsStorageManager;
        this.isSubmitted = false;
        this._isLoading = false;
        this._serverErrors = [];
        makeAutoObservable(this);
    }
    initialize() {
        this.fulfillmentFormReactions.initialize();
        this.orderDetails.initialize();
        this.checkoutTracking.initialize();
    }
    dispose() {
        this.fulfillmentFormReactions.dispose();
        this.orderDetails.dispose();
        this.checkoutTracking.dispose();
    }
    // TODO: get more detailed front-end errors
    get formInvalid() {
        return this.isSubmitted && !this.purchaseOrder.isCheckoutFormValid;
    }
    get isLoading() {
        return this._isLoading;
    }
    get enableMarketingConsents() {
        return this.restaurant.enableMarketingConsents;
    }
    get hasMarketingConsentError() {
        return (this.orderDetails.doesAnyPromotionRequireMarketingConsents &&
            !this.purchaseOrder.isAgreedToMarketingConsents);
    }
    get price() {
        return this.purchaseOrder.price;
    }
    get serverErrors() {
        return this._serverErrors;
    }
    get orderingHoursError() {
        if (this.purchaseOrder.domain.orderingHoursAvailability.isAvailable
            .isDefinitelyFalse) {
            return PProductError.outOfOrderingHours();
        }
        return PProductError.none();
    }
    get error() {
        return this.purchaseOrder.error;
    }
    get fulfillmentMethodsOptions() {
        return this.purchaseOrder.fulfillmentMethodOptions;
    }
    get deliveryPrice() {
        return this.purchaseOrder.deliveryPrice;
    }
    get packagingPrice() {
        return this.purchaseOrder.packagingPrice;
    }
    get isNoContactDeliveryHiddenUnderDelivery() {
        return (this.restaurant.domain.fulfillmentMethods.includes("Delivery") &&
            this.restaurant.domain.fulfillmentMethods.includes("NoContactDelivery"));
    }
    get orderLines() {
        return this.purchaseOrder.orderLines;
    }
    get paymentMediums() {
        return this.restaurant.paymentMediums.raw;
    }
    get isOnlinePayment() {
        return (this.purchaseOrder.paymentMedium !== null &&
            this.purchaseOrder.paymentMedium.method === "Online");
    }
    get contactDetailsLocked() {
        return this.purchaseOrder.contactDetailsLocked;
    }
    get restaurantFranchiseName() {
        return this.restaurant.franchiseName;
    }
    get nextAvailableOrderingDate() {
        return this.purchaseOrder.nextAvailableOrderingDate;
    }
    get promotions() {
        return this.orderDetails.promotionInstances;
    }
    disableMarketingPromotionInstances() {
        this.orderDetails.disableMarketingPromotionInstances();
    }
    unlockContactDetails() {
        this.purchaseOrder.unlockContactDetails();
    }
    get isPaymentMediumRestored() {
        return this.purchaseOrder.isPaymentRestored;
    }
    get canPlaceOrder() {
        return this.purchaseOrder.canPlaceOrder;
    }
    // Used only to show debug info
    get availability() {
        return this.purchaseOrder.availability;
    }
    async placeOrder(appOriginPath, onValidationFailed) {
        var _a;
        this.isSubmitted = true;
        this.fulfillmentFormController.submit();
        this.paymentFormController.submit();
        this.contactFormController.submit();
        this.invoiceFormController.submit();
        this.consentsFormController.submit();
        if (this.isLoading) {
            return;
        }
        if (!this.canPlaceOrder) {
            if (onValidationFailed !== undefined) {
                onValidationFailed();
            }
            return;
        }
        runInAction(() => {
            this._isLoading = true;
        });
        const req = this.purchaseOrder.checkoutData({
            appOrigin: appOriginPath,
            locale: this.locale,
            tracking: this.checkoutTracking.checkoutData,
            fieldTestExperiments: (_a = this.fieldTestsStorageManager.restore()) !== null && _a !== void 0 ? _a : {},
        });
        const res = await this.checkoutService.checkout(req);
        // Unknown server error (eg. 503, 439 etc.)
        if (res === null) {
            runInAction(() => {
                this._isLoading = false;
                this._serverErrors = [
                    I18n.t("orders.checkout.server_error_with_contact", {
                        phoneNumber: this.restaurant.phoneNumbers.join(", "),
                    }),
                ];
            });
            Sentry.logError(new Error("PlaceOrderRequest errors: unknown error"), {
                extra: {
                    errors: ["unknown error"],
                    cartContext: this.purchaseOrder.checkoutLoggingData,
                },
            });
            return;
        }
        // RPC known error
        if (res.tag === "Errors") {
            runInAction(() => {
                this._isLoading = false;
                this._serverErrors = res.contents;
            });
            Sentry.logError(new Error(`PlaceOrderRequest errors: ${res.contents.join(", ")}`), {
                extra: {
                    errors: res.contents,
                    cartContext: this.purchaseOrder.checkoutLoggingData,
                },
            });
            return;
        }
        // Success
        this.placeOrderCallback(res);
    }
}
