import { makeAutoObservable } from "mobx";
import { RArray } from "../../collections";
import { ProductCardCreatorResult } from "./ProductCardCreatorResult";
import { Availability } from "../..";
export class ProductCardCreator {
    constructor(params) {
        this.productInstanceCreator = params.productInstanceCreator;
        this._quantity = params.quantity;
        this.crossSellingCreator = params.crossSellingCreator;
        this.orderingHoursAvailability = params.orderingHoursAvailability;
        makeAutoObservable(this);
    }
    initWithScope(parentScope) {
        this.productInstanceCreator.initWithScope(parentScope);
        this.crossSellingCreator.initWithScope(parentScope);
        this.updateCrossSellingProductTypes();
    }
    get availability() {
        // TODO Rethink cross selling availability here as well?
        return Availability.composite([
            this.productInstanceCreator.instance.availability,
            this.orderingHoursAvailability,
        ]);
    }
    get productTypeIds() {
        return this.productInstanceCreator.productTypeIds;
    }
    get quantity() {
        return this._quantity;
    }
    increment() {
        this._quantity = this.quantity.incremented();
    }
    decrement() {
        this._quantity = this.quantity.decremented();
    }
    get result() {
        if (this.availability.isAvailable.isDefinitelyFalse) {
            return null;
        }
        return new ProductCardCreatorResult(RArray.singleton([
            this.productInstanceCreator,
            this.quantity,
        ]).concatenated(this.crossSellingCreator.selectedItems.map(([creator, quantity, _]) => [
            creator,
            quantity,
        ])));
    }
    get isSplitPossible() {
        return this.productInstanceCreator.isSplitPossible;
    }
    updateCrossSellingProductTypes() {
        this.crossSellingCreator.setInputProductTypeIds(this.productTypeIds);
    }
    split() {
        this.productInstanceCreator.split();
        this.updateCrossSellingProductTypes();
    }
    removeFirstHalf() {
        this.productInstanceCreator.removeFirstHalf();
        this.updateCrossSellingProductTypes();
    }
    removeSecondHalf() {
        this.productInstanceCreator.removeSecondHalf();
        this.updateCrossSellingProductTypes();
    }
    setFirstHalfProductType(productType) {
        this.productInstanceCreator.setFirstHalfProductType(productType);
        this.updateCrossSellingProductTypes();
    }
    setSecondHalfProductType(productType) {
        this.productInstanceCreator.setSecondHalfProductType(productType);
        this.updateCrossSellingProductTypes();
    }
}
