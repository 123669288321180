import I18n from "i18n-js";
import { makeAutoObservable } from "mobx";
import { ParameterMultiSet, } from "../ordering";
import { RSet } from "../collections";
import { formatFulfillmentMethodType } from "./utils";
class ExtractUnavailabilityDetails {
    constructor() {
        this._reasons = RSet.empty();
        this._unavailabilityCausingParameters = [];
    }
    visitAvailable() {
        // NOTICE Intentionally empty
    }
    visitUnavailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this._reasons = this._reasons.union(availability.unavailabilityReasons);
        }
    }
    visitMaybe() {
        // NOTICE Intentionally empty
    }
    visitAsync(availability) {
        availability.asyncAvailability.accept(this);
    }
    visitBoolean(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this._reasons = this._reasons.union(availability.unavailabilityReasons);
        }
    }
    visitComposite(availability) {
        availability.availabilities.forEach((childAvailability) => {
            return childAvailability.accept(this);
        });
    }
    visitDependent(availability) {
        availability.dependency.accept(this);
    }
    visitParametrized(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this._reasons = this._reasons.union(availability.unavailabilityReasons);
            this._unavailabilityCausingParameters.push(availability.unavailabilityCausingParameters);
        }
    }
    visitSchedule(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this._reasons = this._reasons.union(availability.unavailabilityReasons);
        }
    }
    visitPreorder(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this._reasons = this._reasons.union(availability.unavailabilityReasons);
        }
    }
    visitOrderingHours(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this._reasons = this._reasons.union(availability.unavailabilityReasons);
        }
    }
    get reasons() {
        return this._reasons;
    }
    get unavailabilityCausingParameters() {
        return this._unavailabilityCausingParameters.reduce((prev, curr) => prev.union(curr), ParameterMultiSet.empty());
    }
    get customParameters() {
        return this.unavailabilityCausingParameters.custom;
    }
    get internalParameters() {
        return this.unavailabilityCausingParameters.internal;
    }
}
export class PProductUnavailabilityDetails {
    constructor(params) {
        this.availability = params.availability;
        this.restaurant = params.restaurant;
        makeAutoObservable(this);
    }
    get visitor() {
        const extractUnavailabilityDetails = new ExtractUnavailabilityDetails();
        this.availability.accept(extractUnavailabilityDetails);
        return extractUnavailabilityDetails;
    }
    get error() {
        const { reasons } = this.visitor;
        if (reasons.isEmpty) {
            return null;
        }
        if (reasons.includes("Visibility")) {
            return {
                type: "VisibilityUnavailability",
                message: I18n.t("menu.productTemporarilyUnavailable"),
            };
        }
        if (reasons.includes("TemporarilyUnavailable") ||
            reasons.includes("Hidden")) {
            return {
                type: "TemporarilyUnavailable",
                message: I18n.t("menu.productTemporarilyUnavailable"),
            };
        }
        if (reasons.includes("Preorder")) {
            return {
                type: "PreorderUnavailability",
                message: I18n.t("cart.errors.productUnavailable"),
            };
        }
        if (reasons.includes("Schedule")) {
            return {
                type: "ScheduleUnavailability",
                message: I18n.t("cart.errors.productUnavailableAtThisTime"),
            };
        }
        if (this.parametersDetails.length > 0) {
            const result = {
                type: "ParametersUnavailability",
                message: `${I18n.t("cart.errors.invalidProductVariant")}: ${this.parametersDetails.join(", ")}.`,
            };
            return result;
        }
        if (reasons.includes("PriceAvailability") ||
            reasons.includes("ParametrizedAvailability")) {
            return {
                type: "ParametersUnavailability",
                message: I18n.t("cart.errors.invalidProductVariant"),
            };
        }
        return {
            type: "UnknownUnavailability",
            message: I18n.t("cart.errors.productCantBeOrdered"),
        };
    }
    get parametersDetails() {
        const { internalParameters } = this.visitor;
        const details = [];
        if (!internalParameters.fulfillmentMethod.isEmpty) {
            const key = I18n.t("order.delivery_method");
            const value = internalParameters.fulfillmentMethod
                .rawValues()
                .map((fulfillmentMethod) => formatFulfillmentMethodType(fulfillmentMethod))
                .join(", ");
            details.push(`${value} (${key})`);
        }
        if (!internalParameters.splitToHalves.isEmpty) {
            details.push(I18n.t("cart.errors.tooManyParts"));
        }
        details.push(...this.visitor.customParameters.values.entries.mapOptional(([parameterType, choiceIds]) => {
            const parameter = this.restaurant.customParameterTypes.find(parameterType.id);
            if (parameter === null) {
                return null;
            }
            const choices = parameter.choices.objects
                .filtered((choice) => choiceIds.includes(choice.id))
                .map((choice) => choice.name).raw;
            return choices.length > 0
                ? `${choices.join(", ")} (${parameter.name})`
                : `(${parameter.name})`;
        }).raw);
        return details;
    }
}
